import React from 'react';
import { Card } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CompetenceCard = ({ title, items, icon, style }) => {
    return (
        <Card title={<><FontAwesomeIcon icon={icon} style={{ marginRight: '10px' }} /> {title}</>} className="competence-card" bordered={false} style={style}>
            {items.map((item, index) => (
                <p key={index}>{item}</p>
            ))}
        </Card>
    );
};

export default CompetenceCard;
