import React from 'react';
import './APropos.css';
import {Container} from 'react-bootstrap';
import transition from "../../transition";
import {motion} from "framer-motion";

import CV from "../../assets/CV.pdf";

function APropos() {
    return (
        <>
            <div className="background-propos">
                <span></span>
                <span></span>
                <span></span>
            </div>

            <Container className="apropos-container">
                <motion.p className='aboutMe tilt'
                          initial={{opacity: 0}}
                          animate={{opacity: 1}}
                          transition={{
                              duration: 1,
                              delay: 0.2,
                          }}>
                    Bonjour, je suis Thibaud Dufour, développeur Fullstack passionné par la création et
                    l'optimisation de solutions web. Actuellement en Master MIAGE en alternance chez Goodyear,
                    j'ai acquis une solide expérience dans la gestion de projets et le développement de
                    solutions informatiques.

                    <br/><br/>Mon parcours comprend une Licence Professionnelle Réseaux et Génie Informatique,
                    ainsi qu'un DUT Informatique. Au cours de ces formations, j'ai travaillé sur divers projets
                    académiques et professionnels, allant de la création de sites web à l'optimisation des
                    processus industriels.

                    <br/><br/>Chez Goodyear, j'ai eu l'opportunité de travailler en tant qu'apprenti ingénieur
                    informaticien industriel, où j'ai contribué à la digitalisation et à la modernisation des
                    processus de l'usine. Mon rôle impliquait la gestion de projets, le développement de
                    solutions logicielles, et l'optimisation des systèmes existants.

                    <br/><br/>Je maîtrise plusieurs langages et technologies, notamment PHP, Symfony,
                    JavaScript, React, et bien d'autres. En dehors du travail, je suis un passionné de Linux, de
                    nouvelles technologies et de cuisine. Je pratique également la piscine régulièrement pour
                    rester en forme.

                    <br/><br/>Mon objectif professionnel est de continuer à développer des solutions innovantes
                    et à repousser les limites de ma créativité tout en évoluant dans des environnements
                    stimulants.

                    <br/><br/>
                    <a href={CV} download="CV.pdf" className="cv-link">Télécharger mon CV</a>
                </motion.p>
            </Container>
        </>
    );
}

export default transition(APropos);
