import React from 'react';
import Typewriter from 'typewriter-effect';
import './Home.css'
import Social from './Social/Social';
import transition from "../../transition"

const Home = () => {

    return (
        <>
            <div className="background">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div className='mainContainer title'>
                <h1 className='nameStyle'>Thibaud DUFOUR</h1>
                <div className='inlineChild'>
                    <Typewriter
                        options={{
                            strings: [
                                'Développeur Fullstack',
                                'Spécialiste PHP Laravel et Symfony',
                                'Créateur de Solutions Web',
                                'Passionné par les Nouvelles Technologies',
                                'Toujours en quête d\'apprendre'
                            ],
                            autoStart: true,
                            loop: true,
                            delay: 25,
                            deleteSpeed: 15,
                        }}
                    />
                </div>
                <p className='description'>
                    Passionné par le développement web et les nouvelles technologies, je suis spécialisé en PHP et en
                    développement Fullstack.
                </p>
                <Social/>
            </div>
        </>
    );
};

export default transition(Home);
