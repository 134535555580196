import React from 'react';
import {Card, Tag} from 'antd';
import {Row, Col} from 'react-bootstrap';
import {motion} from "framer-motion";

const ProjetsSection = ({title, projets, color}) => {
    return (
        <div className="section-container">
            <h2 className="section-title">{title}</h2>
            <hr/>
            <Row className="justify-content-md-center mt-5">
                {projets.map((projet, index) => (
                    <Col key={index} lg="4" md="6" sm="12">
                        <motion.div className="card-container">
                            <Card
                                className="project-card"
                                title={projet.title}
                                bordered={false}
                                style={{backgroundColor: projet.backgroundColor}}
                            >
                                <img className="card-image mb-2" src={projet.imgSrc} alt={projet.title}/>
                                <div className="tags-container">
                                    {projet.tags.map((tag, idx) => (
                                        <Tag key={idx} color={color[tag]}>{tag.toString().toUpperCase()}</Tag>
                                    ))}
                                </div>
                                <p className='mt-2 project-description'>{projet.description}</p>
                                {projet.liveLink && (
                                    <span className="project-description">Vous pouvez consulter le projet en <a href={projet.liveLink} target='_blank' rel='noopener noreferrer' className='link-projets'>cliquant ici</a></span>
                                )}
                            </Card>
                        </motion.div>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default ProjetsSection;
