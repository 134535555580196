import React from 'react';
import transition from '../../transition';
import {motion} from "framer-motion";

import ProjetsSection from './ProjetsSection';
import './Projets.css';

import LaVraieMifa from '../../assets/LaVraieMifa.jpg';
import Uama from '../../assets/uama.png';
import competition from '../../assets/compet.png';
import upjveats from '../../assets/upjveats.png';
import unity from '../../assets/unity.png';
import modele3D from '../../assets/modele3D.png';
import edumeter from '../../assets/edumeter.png';

const Projets = () => {

    const color = {
        php: "#777bb3",
        symfony: "#000000",
        laravel: "#ff2d20",
        javascript: "#f0db4f",
        react: "#61dafb",
        typescript: "#007acc",
        twig: "#8dc74d",
        angular: "#dd0031",
        server: "#8b4513",
        spring: "#6db33f",
        thymeleaf: "#005f0f",
        jquery: "#0769ad",
        unity: "#222c37",
        csharp: "#9b4f96",
        karaf: "#cc0033",
        camel: "#e77b00",
        velocity: "#6b0b6b",
        java: "#007396",
        groovy: "#4298b8",
        mail: "#c71610",
        nas: "#2b5797",
        web: "#4a90e2",
        sql: "#e38c00",
        threejs: "#3f7b9d",
        nodejs: "#68A063",
        express: "#303030",
        mongodb: "#47A248"
    };


    const projetsPersonnels = [
        {
            title: "La Vraie Mifa",
            backgroundColor: "#e84545",
            imgSrc: LaVraieMifa,
            tags: ["php", "symfony", "server", "twig", "jquery", "mail"],
            description: "Création d'un site web collaboratif pour explorer l'écosystème Symfony et la configuration de serveur.",
            liveLink: "https://lavraiemifa.fr/"
        },
        {
            title: "Administration d'un serveur Linux",
            backgroundColor: "#2e1dec",
            imgSrc: LaVraieMifa,
            tags: ["server", "nas", "mail", "sql", "web", "php"],
            description: "Configuration et gestion d'un serveur Linux pour apprendre et comprendre son fonctionnement interne.",
        }
    ];

    const projetsProfessionnels = [
        {
            title: "UAMA",
            backgroundColor: "#FFACAC",
            imgSrc: Uama,
            tags: ["react", "symfony", "server", "sql", "mail"],
            description: "Développement d'un système d'information pour automatiser et simplifier la gestion administrative des assistantes maternelles, incluant la gestion des plannings, des impôts, des contrats et des documents.",
            liveLink: "https://www.uama.fr"
        },
        {
            title: "Gestion de compétition",
            backgroundColor: "#60cee1",
            imgSrc: competition,
            tags: ["spring", "thymeleaf"],
            description: "Développement d'un système de gestion de compétition pour découvrir les technologies Spring et Thymeleaf, dans le cadre d'un cours universitaire sur les architectures multi-tiers complexes. Le projet comprenait la création de modèles, de services, de web services et de vues, avec un fort accent sur le travail d'équipe et l'organisation."
        },
        {
            title: "UPJV Eats",
            backgroundColor: "#E45A84",
            imgSrc: upjveats,
            tags: ["symfony", "twig", "jquery", "mail"],
            description: "Développement d'une application web de type carte interactive destinée aux nouveaux étudiants de l'UPJV, permettant de localiser les points de restauration les plus proches selon leurs pôles universitaires. Les utilisateurs peuvent rechercher, filtrer et noter les restaurants. Le projet a également servi à découvrir et appliquer la conduite de projet MAETIC.",
            liveLink: "https://tmbsoftware.lavraiemifa.fr"
        },
        {
            title: "Développement d'un jeu sous Unity",
            backgroundColor: "#60cee1",
            imgSrc: unity,
            tags: ["unity", "csharp"],
            description: "Participation au développement d'un jeu de type escape game en 3D avec Unity. Répartition des tâches sur la création d'énigmes, intégration d'assets graphiques et sonores. Maîtrise des compétences essentielles pour développer un jeu engageant et optimisé pour diverses plateformes."
        },
        {
            title: "Visualisation de modéle 3D",
            backgroundColor: "#52e0a3",
            imgSrc: modele3D,
            tags: ["php", "threejs", "sql"],
            description: "Développement d'une application web utilisant Three.js pour visualiser des objets 3D directement sur un site web. Ce projet a aidé les chercheurs dans un laboratoire en leur offrant une solution pratique pour afficher et manipuler des modèles 3D interactifs.",
            liveLink: "https://labo.lavraiemifa.fr/pages/afficheModel.php?modele=1"
        },
        {
            title: "EduMeter",
            backgroundColor: "#3bb727",
            imgSrc: edumeter,
            tags: ["angular", "typescript", "nodejs", "mongodb", "express"],
            description: "Développement d'une plateforme web permettant aux étudiants de s'auto-évaluer par compétence sur divers projets académiques. Ce projet visait à fournir une solution interactive pour la gestion et l'évaluation des compétences et des projets, en utilisant Angular et TypeScript pour le frontend, et des technologies modernes pour le backend. J'ai développé mes compétences en Angular, TypeScript et gestion de bases de données NoSQL.",
        }
    ];

    return (
        <motion.div className="projets-container">
            <ProjetsSection title="Projets Professionnels/Académiques" projets={projetsProfessionnels} color={color}/>
            <ProjetsSection title="Projets Personnels" projets={projetsPersonnels} color={color}/>
        </motion.div>
    );
};

export default transition(Projets);
