import React from 'react';
import transition from '../../transition';
import CompetenceSection from './CompetenceSection';
import './Competence.css';

const Competences = () => {
  return (
      <div className="mainContainer">
        <div className="competenceContainer">
          <CompetenceSection title="Savoir-Faire" competences={savoirFaireCompetences} icons={savoirFaireIcons} styles={savoirFaireStyles} />
          <CompetenceSection title="Savoir-Être" competences={savoirEtreCompetences} icons={savoirEtreIcons} styles={savoirEtreStyles} />
        </div>
      </div>
  );
};

const savoirFaireCompetences = [
  {
    title: "Projets Académiques",
    items: [
      "Développement et intégration de systèmes d'information",
      "Programmation et mise en place de scripts",
      "Design d'interface utilisateur (UI) et expérience utilisateur (UX)",
      "Tests et validation de logiciels",
    ],
  },
  {
    title: "Projets Personnels",
    items: [
      "Optimisation des processus administratifs",
      "Automatisation des tâches répétitives",
      "Gestion de serveurs Linux",
      "Développement de sites web personnels",
    ],
  },
  {
    title: "Expérience en Alternance",
    items: [
      "Gestion de projets et coordination d'équipe",
      "Analyse des besoins utilisateurs et rédaction de cahiers des charges",
      "Développement de solutions logicielles et optimisation des systèmes existants",
      "Formation et support aux utilisateurs",
    ],
  },
];

const savoirFaireIcons = ['project-diagram', 'code', 'server'];
const savoirFaireStyles = [
  { backgroundColor: '#8e44ad', color: '#fff' }, // Violet
  { backgroundColor: '#27ae60', color: '#fff' }, // Vert
  { backgroundColor: '#2980b9', color: '#fff' }  // Bleu
];

const savoirEtreCompetences = [
  {
    title: "Compétences Personnelles",
    items: [
      "Capacité d'écoute et de compréhension des besoins des utilisateurs",
      "Collaboration et travail d'équipe",
      "Adaptabilité face aux nouvelles technologies",
      "Sens du détail et rigueur",
    ],
  },
  {
    title: "Expérience en Alternance",
    items: [
      "Esprit d'initiative et proactivité",
      "Gestion du stress et respect des délais",
      "Aptitude à la communication claire et efficace",
      "Esprit analytique et résolution de problèmes",
    ],
  },
];

const savoirEtreIcons = ['user', 'users', 'cogs', 'tasks'];
const savoirEtreStyles = [
  { backgroundColor: '#e74c3c', color: '#fff' }, // Rouge
  { backgroundColor: '#f39c12', color: '#fff' }  // Orange
];

export default transition(Competences);
