import React from 'react';
import { motion } from 'framer-motion';
import { Row, Col } from 'react-bootstrap';
import CompetenceCard from './CompetenceCard';

const CompetenceSection = ({ title, competences, icons, styles }) => {
    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1, scale: 1,
            transition: { delayChildren: 0.3, staggerChildren: 0.2 }
        }
    };

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: { y: 0, opacity: 1 }
    };

    return (
        <div className="section-container">
            <h2 className="section-title">{title}</h2>
            <hr />
            <Row className="justify-content-md-center">
                {competences.map((competence, index) => (
                    <Col key={index} lg="4" md="6" sm="12">
                        <motion.div className="card-container" variants={container} initial="hidden" animate="visible">
                            <motion.div className="card-wrapper" variants={item}>
                                <CompetenceCard title={competence.title} items={competence.items} icon={icons[index]} style={styles[index]} />
                            </motion.div>
                        </motion.div>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default CompetenceSection;
