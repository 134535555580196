import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
import transition from "../../transition";

import { FaGraduationCap, FaNetworkWired, FaCode, FaMicroscope } from "react-icons/fa";
import "./Diplomes.css";

function Diplomes() {
  const elements = [
    {
      date: "2022 - 2024 à Amiens",
      icon: <FaGraduationCap />,
      title: "Master MIAGE",
      subtitle: "Master Méthodes Informatiques Appliquées à la Gestion des Entreprises",
      description: "Formation en informatique, avec spécialisation en développement web et gestion de projet, acquise en 2 ans en alternance.",
      contentStyle: { background: "#2D4059", color: "#fff" },
      iconStyle: "icon-color-default",
      link: "https://miage-amiens.fr"
    },
    {
      date: "2021 - 2022 à Amiens",
      icon: <FaNetworkWired />,
      title: "Licence Pro RGI",
      subtitle: "Licence professionnelle Réseaux et Génie Informatique",
      description: "Formation en informatique, avec spécialisation en développement web et framework. Réalisée en un an en alternance.",
      contentStyle: { background: "#E84545", color: "#fff" },
      iconStyle: "icon-color-laravel",
      link: "https://www.iut-amiens.fr/licences-professionnelles/"
    },
    {
      date: "2019 - 2021 à Amiens",
      icon: <FaCode />,
      title: "DUT Informatique",
      subtitle: "Diplôme Universitaire de Technologie Informatique",
      description: "Acquisition des bases de l'informatique. Réalisé en deux ans.",
      contentStyle: { background: "#4058F2", color: "#fff" },
      iconStyle: "icon-color-symfony",
      link: "https://www.iut-amiens.fr/informatique/"
    },
    {
      date: "2017 - 2019 à Compiègne",
      icon: <FaMicroscope />,
      title: "Bac S SI",
      subtitle: "Baccalauréat Scientifique spécialité Sciences de l'Ingénieur",
      description: "Acquisition des bases en sciences de l'ingénieur, avec un focus sur les matières scientifiques et techniques.",
      contentStyle: { background: "#66CC66", color: "#fff" },
      iconStyle: "icon-color-php",
      link: "https://www.lycee-mireille-grenet.fr"
    }
  ];

  return (
      <>
        <ul className="background">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>

        <div className="mainContainer">
          <VerticalTimeline lineColor={'rgb(124, 123, 123)'}>
            {elements.map((element, index) => (
                <VerticalTimelineElement
                    key={index}
                    className="vertical-timeline-element--work"
                    contentStyle={element.contentStyle}
                    contentArrowStyle={{borderRight: `7px solid ${element.contentStyle.background}`}}
                    date={element.date}
                    iconStyle={{background: element.contentStyle.background, color: "#fff"}}
                    icon={element.icon}
                >
                  <h3 className="vertical-timeline-element-title">
                    <a href={element.link} target="_blank" rel="noopener noreferrer" className="timeline-link">
                      {element.title}
                    </a>
                  </h3>
                  {element.subtitle && (
                      <h6 className="vertical-timeline-element-subtitle fst-italic">
                        {element.subtitle}
                      </h6>
                  )}
                  <p>{element.description}</p>
                </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </div>
      </>
  );
}

export default transition(Diplomes);
