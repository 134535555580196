export const antdThemeLight = {
  components: {
  },
  token: {
    colorText: 'white',
    fontSize: 18
  },
};
export const antdThemeDark = {
  components: {

  },
  token: {
    colorText : 'white',
    fontSize: 18,
    colorBgContainer : 'black'
  },
};
