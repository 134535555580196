import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import './Header.css';
import { Link, useLocation } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { FaUser, FaGraduationCap, FaHome, FaTools, FaProjectDiagram } from 'react-icons/fa';

const Header = () => {
  const headerAnimation = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 500,
  });

  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [expanded, setExpanded] = useState(false);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setExpanded(false); // Réduire la barre de navigation après avoir cliqué sur un lien
  };

  return (
      <animated.header className="header" style={headerAnimation}>
        <Navbar expand="lg" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
          <Container>
            <Navbar.Brand className="brand d-lg-none">Thibaud DUFOUR</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link className={`header-navbar-link ${activeLink === '/APropos' ? 'active' : ''}`} onClick={() => handleLinkClick('/APropos')}>
                  <Link to="/APropos">
                    <FaUser /> À propos de moi
                  </Link>
                </Nav.Link>
                <Nav.Link className={`header-navbar-link ${activeLink === '/diplomes' ? 'active' : ''}`} onClick={() => handleLinkClick('/diplomes')}>
                  <Link to="/diplomes">
                    <FaGraduationCap /> Diplômes
                  </Link>
                </Nav.Link>
                <Nav.Link className={`header-navbar-link ${activeLink === '/' ? 'active' : ''}`} onClick={() => handleLinkClick('/')}>
                  <Link to="/">
                    <FaHome /> <span className="d-none d-lg-inline">Thibaud DUFOUR</span>
                  </Link>
                </Nav.Link>
                <Nav.Link className={`header-navbar-link ${activeLink === '/competence' ? 'active' : ''}`} onClick={() => handleLinkClick('/competence')}>
                  <Link to="/competence">
                    <FaTools /> Compétences
                  </Link>
                </Nav.Link>
                <Nav.Link className={`header-navbar-link ${activeLink === '/projets' ? 'active' : ''}`} onClick={() => handleLinkClick('/projets')}>
                  <Link to="/projets">
                    <FaProjectDiagram /> Projets
                  </Link>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </animated.header>
  );
};

export default Header;
